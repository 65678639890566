import React, {useEffect} from 'react';
import Helmet from 'react-helmet';
import styles from './Addons.module.scss';

/**
 *
 *
 * @return {*}
 */
const VimeoAddons = () => {
  useEffect(() => {
    // window.VIDEOASK_EMBED_CONFIG = {
    //   'kind': 'widget',
    //   'url': 'https://www.videoask.com/f8lqku29h',
    //   'options': {
    //     'widgetType': 'VideoThumbnailWindow',
    //     'text': '',
    //     'backgroundColor': '#0B3587',
    //     'position': 'bottom-right',
    //     'dismissable': false,
    //   },
    // };
    setTimeout(() => {
      // window.location.href = '/vimeo-addons/toolkit';
    }, 8000);
    const isVideoaskMessage = (message) =>
      message.origin === 'https://www.videoask.com' && message.data &&
      message.data.type && message.data.type.startsWith('videoask_');

    window.addEventListener('message', (message) => {
      if (!isVideoaskMessage(message)) {
        return;
      }
      if (message?.data?.type === 'videoask_question_submitted') {
        if (message?.data?.optionId ===
          '2c24ff9c-d40f-4e90-a864-ed30757da45d') {
          window.top.postMessage('showToolkitConfirmation', '*');
        } else if (message?.data?.optionId ===
          '9c3c7980-88ed-4a7b-bfa6-8a1311982560') {
          window.top.postMessage('showCertConfirmation', '*');
        } else if (message?.data?.optionId ===
          '51ff5778-a943-4ef5-b617-9d576b9b5c53') {
          window.top.postMessage('showHcdConfirmation', '*');
        }
      }
    });
  }, []);

  const closeModal = () => {
    window.top.postMessage('closeIframe', '*');
  };


  return (
    <>
      <Helmet>
        <title>Vimeo Addons</title>
        <script src="https://www.videoask.com/embed/embed.js"></script>
      </Helmet>
      <div className={styles.vimeoModal}>
        <button className={styles.close} onClick={closeModal}>
          <span aria-hidden="true">×</span>
          <span className={styles.srOnly}>Close</span>
        </button>
        <iframe
          // src="https://www.videoask.com/f8lqku29h"
          src="https://www.videoask.com/f9gneywhj"
          allow="camera; microphone; autoplay; encrypted-media;"
          width="100%"
          height="100%"
          frameBorder="0"
        />
      </div>
    </>
  );
};

export default VimeoAddons;
